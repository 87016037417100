import NewsAndEvents from './classes/news-and-events';

declare global {
    interface Window {
        saitNewsAndEvents: any;
     }
}

const globalInit = function () {
    // global js functions
    window.saitNewsAndEvents = new NewsAndEvents();
}
globalInit();