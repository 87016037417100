// import Serializer from "./serializer";
import AxiosConfig from '../data/axios.config';
const Mustache = require('mustache');
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
const noresultsTemplate = require('../../coned/templates/no-result').default;
class NewsAndEvents {
    axiosClient: AxiosInstance;
    constructor() {
        this.axiosClient = axios.create(AxiosConfig);
        this.init();
    }

    init(){
        let elementWrappers = document.querySelectorAll(".custom-events-news-sait");
        let urlRoute = "";
        if(elementWrappers != null)
        {
            elementWrappers.forEach( item => 
            {
                let resultsContainer = item;
                let dataApiCallType =  item.getAttribute("data-api-type");
                let dataLimit =  item.getAttribute("data-limit");
                let dataCategories =  item.getAttribute("data-categories");
                let dataCategoryType = item.getAttribute("data-category-type");
                let dataTypeDisplay = "";
                if(dataApiCallType == "events")
                {
                    let display = item.getAttribute("data-display-type");
                    if(display){
                        dataTypeDisplay = display;
                    }
                    urlRoute = "events";
                }
                else if(dataApiCallType == "news")
                {
                    urlRoute = "news";
                }
                let url = `${urlRoute}/latest/${dataLimit}/${dataCategories}`;
                if(dataApiCallType == "news"){
                    url = `${AxiosConfig.baseURL}/news/v1/0/${dataCategories}?limit=${dataLimit}`;
                }
                else if(dataApiCallType == "events"){
                    url = `${AxiosConfig.baseURL}/events/v2/${dataCategories}?limit=${dataLimit}&page=0&strictFilter=true`;
                }
                this.get(url, dataApiCallType, resultsContainer, dataTypeDisplay);
            });
        }
    }

    get(url: string, dataApiType: string, resultsContainer: Element, dataTypeDisplay: any){
        let self = this;
        resultsContainer.innerHTML = "";
        if(dataApiType){
            let template : any;
            if(dataApiType == "news"){
                template = require('../../coned/templates/news').default;
            }
            else if(dataApiType == "events"){
                if(dataTypeDisplay){
                    if(dataTypeDisplay == "home")
                    {
                        template = require('../../coned/templates/events-mini-full').default;
                    }
                    else{
                        template = require('../../coned/templates/events-mini-landing').default;   //mini
                    }
                }
                else{
                    template = require('../../coned/templates/events-landing-item').default;   //regular
                }
            }
            this.axiosClient.get(url)
            .then(function (response) {
                let data = response.data.data as Array<any>;
                if (data) {
                    if(data.length > 0){
                        data.forEach(function(item) {
                            item.title = self.htmlDecode(item.title);
                            //console.log(Utility.htmlDecode(item.title));
                            resultsContainer.innerHTML += Mustache.render(template, item);
                        });
                    }
                    else{
                        if(dataTypeDisplay == "home"){
                            resultsContainer.innerHTML = "";
                        }
                        else{
                            resultsContainer.innerHTML = Mustache.render(noresultsTemplate, {});
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                resultsContainer.innerHTML = Mustache.render(noresultsTemplate, {});
            });
        }
        else{
            resultsContainer.innerHTML = Mustache.render(noresultsTemplate, {});
        }
    }

    htmlDecode(value) {
        var doc = new DOMParser().parseFromString(value, "text/html");
        if (doc) {
            return doc.documentElement.textContent;
        }
        return "";
    }

}

export default NewsAndEvents;